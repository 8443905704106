<template>
  <v-data-table :headers="headers" :items="pumps" :search="search" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat color="#598639" dark >
        <v-toolbar-title>{{ $t("pumps") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="650px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Pump") }}
            </v-btn>
          </template>
          <v-card  style="border-style: solid;  border-color: white;">
            <v-toolbar color="black">
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-gas-station</v-icon> <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text >
              <add-pump-vue 
               v-model="editedItem" 
               @valid="v => valid = v" 
               :disp="dispensers"
               :term="terminals"
               :reads="readers"
              ></add-pump-vue>
            </v-card-text>

            <v-card-actions>
              <v-btn color="warning" @click="close"> {{$t('Cancel')}} </v-btn>
               <v-spacer></v-spacer>
              <v-btn color="#196619" class="white--text" @click="save" :disabled="!valid" :loading="loading2"> {{$t('Save')}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{$t('Cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{$t('OK')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.terminal_id="{ item }">
        <span> {{terminals.find(v => v.id === item.terminal_id)?.name || 'Disabled'}}</span><span class="text-caption">(id:{{item.terminal_id}})</span>
    </template>
    <template v-slot:item.dispenser_id="{ item }">
        <span> {{dispensers.find(v => v.id === item.dispenser_id)?.name || 'Disabled'}}</span><span class="text-caption">(id:{{item.dispenser_id}})</span>
    </template>
    <template v-slot:item.tagreader_id="{ item }">
        <span> {{readers.find(v => v.id === item.tagreader_id)?.name || 'Disabled'}}</span><span class="text-caption">(id:{{item.tagreader_id}})</span>
    </template>
    <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status === 1"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else  color="red"> mdi-cancel</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  color="green" class="mr-2" @click="setItem(item)"> mdi-cog </v-icon>
      <v-icon  color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon  color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import AddPumpVue from '../components/AddPump.vue'
export default {
  name: "pumps" ,
  components: {
    AddPumpVue
  },

  data() {
    return {
        dialog: false,
        dialogDelete: false,
        pumps: [],
        terminals: [],
        dispensers: [],
        readers: [],
        valid: false,
        loading: false,
        loading2: false,
        rules: [
          v => v !== ''||  this.$t('Item is required'),
          v => v.length <= 20 || this.$t('A maximum  characters exceeded')
        ], 
        editedItem: {
          name: '',
          pumpNumber: null,
          address: '',
          tagreader_id: 0,
          terminal_id: 0,
          status: 0
        },
        defaultItem: {
          name: '',
          pumpNumber: null,
          address: '',
          tagreader_id: 0,
          terminal_id: 0,
          status: 0
        },
        editedIndex: -1,
        search: '',
        headers: [
          {
            text: this.$t('ID'),
            align: 'start',
            sortable: true,
            value: 'id',
          },
          {
            text: this.$t('Name'),
            align: 'center',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$t('Pump No.'),
            align: 'center',
            sortable: true,
            value: 'pump_no',
          },
          {
            text: this.$t('Terminal'),
            align: 'left',
            sortable: true,
            value: 'terminal_id',
          },
          {
            text: this.$t('Dispenser'),
            align: 'left',
            sortable: true,
            value: 'dispenser_id',
          },
          {
            text: this.$t('Tagreader'),
            align: 'left',
            sortable: true,
            value: 'tagreader_id',
          },
          {
            text: this.$t('Active'),
            align: 'center',
            sortable: true,
            value: 'status',
          },
          
          {
            text: this.$t('Actions'),
            align: 'center',
            value: 'actions',
          },
        
        
        ]
        
    }
  },
  methods: {
      async initialize () {
        try {
          this.loading = true
          const bus = await this.$http.get("/pump")
          this.pumps = bus.data
          const term = await this.$http.get("/device/terminals")
          this.terminals = term.data
          const disp = await this.$http.get("/device/dispensers")
          this.dispensers = disp.data
          const readrs = await this.$http.get("/device/readers")
          this.readers = readrs.data
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
      
    },
    
    editItem (item) {
      this.editedIndex = this.pumps.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.pumps.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      let res = {};
      try {
          res = await this.$http.delete(`/pump/${this.editedItem.id}`)
          this.pumps.splice(this.editedIndex, 1)
          this.closeDelete()
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.closeDelete()
        } 
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    // validate () {
    //     return this.$refs.form.validate()
    // },

    async save () {
      let res = null
      if (this.editedIndex > -1) {
        try {
          this.loading2 = true
          res = await this.$http.put("/pump", this.editedItem)
          Object.assign(this.pumps[this.editedIndex], this.editedItem)
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } finally{
          this.loading2 = false
        }
        
      } else {
        try {
          this.loading2 = true
          await this.$http.post("/pump", this.editedItem)
          this.pumps.push(this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      }
      this.close()
      await this.initialize()
    },

    setItem(item) {
      this.$router.push({
        name: "PumpSettings", //use name for router push
        params: { data: item} 
      })
    }
  },

  computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$t('Add New Pump') : this.$t('Edit Pump')
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      // editedItem: {
      //   handler() {
      //     this.$refs.form.validate()
      //   },
      //   deep: true
      // }
    },

    async created () {
      await this.initialize()
    },
  
    

}
</script>

