<template>
  <v-form v-model="valid" refs="form">
    <v-container class="mt-3">
      <v-col cols="12">
        <v-text-field  @input="update('type', value)" dense v-model="value.name" :label="$t('Name')" :counter="20" :rules="rules" required outlined>
        </v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field  @input="update('type', value)" dense v-model.number="value.pump_no" :label="$t('Pumo No.')" 
        :counter="1" type="number"  :rules="[rules[0]]" required
          outlined></v-text-field>
      </v-col>
      <v-col cols="12" class="" >
        <v-select
          dense
          v-model="value.terminal_id"
          :label="$t('Terminal')"
          :items="term"
          item-text="name"
          item-value="id"
          persistent-hint
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" class="" >
        <v-select
          dense
          v-model.number="value.dispenser_id"
          :label="$t('Dispenser')"
          :items="disp"
          item-text="name"
          item-value="id"
          persistent-hint
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" class="">
        <v-select
          dense
          v-model.number="value.tagreader_id"
          :label="$t('Tagreader')"
          :items="reads"
          item-text="name"
          item-value="id"
          persistent-hint
          outlined
        ></v-select>
      </v-col>  
      <v-col cols="12">
        <v-col cols="6">
          <v-checkbox  @input="update('type', value)" v-model.number="value.status" :label="$t('Active')" color="indigo darken-3" :true-value="1"
            :false-value="0" ></v-checkbox>
        </v-col>
      </v-col>
    </v-container>
  </v-form>

</template>

<script>
import { watch } from 'fs'

export default {
  name: "addPump",
  props: ['value', 'disp', 'term', 'reads'],

  data() {
    return {
      valid: false,
      rules: [
        v => v !== '' || this.$t('Item is required'),
        v => v.length <= 45 || this.$t('A maximum  characters exceeded')
      ],
      selRequired: [v =>  v > 0 || this.$t('Item is required')],
      addressLabel: "HEX",
      
    }
  },
  computed: {
    computedHex() {
      if (this.value.address.includes("0x", 0)) {
        this.addressLabel = 'DEC'
        return parseInt(this.value.address).toString(10)
      } else {
        this.addressLabel = 'HEX'
        return parseInt(this.value.address).toString(16) || 0
      }
    },  
  },
  watch:{
    valid (newv, oldv) {
        this.$emit('valid', this.valid)
    },

  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    }
  }
}
</script>